.mainBox-mensagens {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  bottom: 2%;
  right: 1%;
  width: 3rem;

  .mainBox-toogle {
    button {
      background: #696ffd;
      border: 2px solid #ffffff;
      box-shadow: 0px 4px 8px rgba(2, 6, 102, 0.2);
      border-radius: 8px;
      width: 3rem;
      color: #fff;

      &:hover,
      &:target {
        background: #3e44c8;
        color: #fff;
      }

      &:focus {
        box-shadow: 0px 4px 8px rgba(2, 6, 102, 0.2) !important;
        border: 2px solid #ffffff !important;
      }
    }
  }

  .mainBox-body {
    background: #ffffff;
    border-radius: 8px;
    will-change: transform;
    width: 15rem;
    height: 20rem;
    display: block;
    position: fixed;
    bottom: 8%;
    right: 1%;
    padding: 0.25rem;

    &.hidden {
      display: none;
    }

    .containerBox {
      border: 1px solid #edeefa;
      box-sizing: border-box;
      border-radius: 8px;
      width: 100%;
      height: 85%;
      padding: 0.25rem;
      margin-bottom: 0.25rem;
      display: flex;
      flex-direction: column;

      .img-avatar {
        height: 25px;
      }

      .containerBox-header {
        display: flex;
        justify-content: flex-start;
        height: 5%;
      }

      .containerBox-body {
        height: 85%;
      }

      .containerBox-footer {
        display: flex;
        justify-content: flex-end;
        height: 5%;
      }
    }

    .form-group {
      margin: 0;
      width: 100%;

      .input-group {
        background: #edeefa;
        border-radius: 8px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 13px;
        color: #6f6b6b;
        border: unset;
        width: 100%;
        height: 40px;

        input {
          background: transparent;
          border: unset !important;

          &:focus {
            border: unset !important;
            box-shadow: unset !important;
          }
        }

        .input-group-prepend {
          .input-group-text {
            border: unset;
            background: transparent;
            color: #3e44c8;
            font-size: 20px;
          }
        }
      }
    }
  }
}
