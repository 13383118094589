@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

// If you want to override variables do it here
@import "variables";

// Import styles with default layout.
// If you are going to use dark layout please comment next line
@import "~@coreui/coreui-pro/scss/coreui.scss";

// Import styles with dark layout
// If you want to use dark layout uncomment next line
//@import "~@coreui/coreui-pro/scss/themes/dark/coreui-dark.scss";

// Temp fix for reactstrap
@import "~@coreui/coreui-pro/scss/_dropdown-menu-right.scss";

// If you want to add something do it here
@import "custom";

@import "dropdown-menu";

@import "mainBox-mensagem";

// ie fixes
@import "ie-fix";

// temp fixes
@import "fixes";

// Spinkit
$spinkit-spinner-color: $body-color;
@import "~spinkit/scss/spinkit.scss";

// Container sizes
// --------------------------------------------------

.bg-azulNovo {
  background-color: #002179 !important;
}
.bg-cinzaNovo {
  background-color: #a9a9a9 !important;
}

  .container {
    width: 95%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  .rh-icon {
    background: url('../assets/icons/N_azul_Painel.png') no-repeat 0 0;
  }

  .rh-icon:hover {
    background: url('../assets/icons/N_vermelho_Alertas.png') no-repeat 0 -100px;
  }


  .modulosItem {
    float: left;
    margin-right: 40px;

    .nav-link {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 27px;
      display: flex;
      align-items: center;
      color: #6f6b6b;

      svg {
        margin-right: 5px;
      }

      &.link-rh {
        &.active,
        &:hover {
          color: #042279;
          border-radius: 5px;
          border-bottom: 4px solid #042279;
        }
      }

      &.link-rotinas {
        &.active,
        &:hover {
          color: #fc9404;
          border-radius: 5px;
          border-bottom: 4px solid #fc9404;
        }
      }

      &.link-alertas {
        &.active,
        &:hover {
          color: #c20404;
          border-radius: 5px;
          border-bottom: 4px solid #c20404;
        }
      }

      &.link-novizap {
        &.active,
        &:hover {
          color: #04ae4b;
          border-radius: 5px;
          border-bottom: 4px solid #04ae4b;
        }
      }

      &.link-mural {
        &.active,
        &:hover {
          color: #042279;
          border-radius: 5px;
          border-bottom: 4px solid #042279;
        }
      }
      &.link-mov {
        &.active,
        &:hover {
          color: rgb(0, 33, 121);
          border-radius: 5px;
          border-bottom: 4px solid rgb(0, 33, 121);
        }
      }

      &.link-logi {
        &.active,
        &:hover {
          color: #975dd6;
          border-radius: 5px;
          border-bottom: 4px solid #975dd6;
        }
      }

      &.link-prod {
        &.active,
        &:hover {
          color: #ff9600;
          border-radius: 5px;
          border-bottom: 4px solid #ff9600;
        }
      }

      &.link-seg {
        &.active,
        &:hover {
          color: #4ac89b;
          border-radius: 5px;
          border-bottom: 4px solid #4ac89b;
        }
      }
    }
  }


  /* large devices */
  @media (min-width: 1200px) {
    .container {
       max-width: 1280px;
     }
  }
  /* extra large devices */
  @media screen and (min-width: 1800px) {
    .container {
        max-width: 1280px;
    }
  }

  // /* extra mobile devices */
  // @media screen and (min-width: 1800px) {
  //   .container {
  //       max-width: 1280px;
  //   }
  // }