.dorpdown-menu-header {
  margin: 0 0.5rem;
  background: #fff;
  padding: 14px 0;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 144.14%;
  display: flex;
  align-items: center;
  color: #3e44c8;
  justify-content: space-between;
  border-bottom: 1px solid #abb2f2;

  .badge {
    border-radius: 1rem !important;
  }
}

.dropdown-menu-item {
  border: 1px solid #b5b6ba !important;
  border-radius: 8px !important;
  margin: 0.5rem !important;
  padding: 0.5rem;
  width: unset;
  cursor: unset;

  &:hover {
    border: 1px solid #3e44c8 !important;
    background: transparent;
  }

  .text-colaborador {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #6f6b6b;
  }

  .text-mensagem {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #958f8f;
    white-space: normal;
  }

  .divBtn {
    margin-top: 0.5rem;
    text-align: center;

    button {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-transform: capitalize;
      color: #6f6b6b;
      background: transparent;
      border: unset;
      width: 100%;
      justify-content: center;

      &:hover {
        background: #edeefa;
        border-radius: 8px;
        color: #3e44c8;
      }
    }
  }
}

.dropdown-menu-item-conta {
  border-radius: 8px !important;
  border: unset !important;
  margin: 0 0.5rem !important;
  padding: 0.25rem 0;
  width: unset;

  &:hover {
    background: transparent;
  }

  .divBtn {
    padding: 0.25rem 0;
    margin: 0;
    text-align: start;
    border-bottom: 1px solid #edeefa;
    width: 100%;

    .divApp {
      display: flex;
      justify-content: space-between;
      margin-top: 0.5rem;
    }

    button {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      // text-transform: capitalize;
      color: #6f6b6b;
      background: transparent;
      border: unset;
      justify-content: start;
      padding: 0.5rem;

      .badge {
        border-radius: 1rem !important;
        top: unset;
      }

      &.wd100 {
        width: 100%;
      }

      &.wd50 {
        width: 50%;
      }

      &.perfil {
        justify-content: space-between;
        flex-direction: row;

        div {
          width: 70%;
          flex-direction: column;
          text-align: start;

          &.div-avatar {
            width: 30%;

            .img-avatar {
              max-width: 70%;
            }
          }

          strong {
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: center;
            // text-transform: capitalize;
            margin: 0;
          }

          span {
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 15px;
            color: #b5b6ba !important;
          }
        }
      }

      strong {
        margin-left: 0.5rem;
      }

      &:hover {
        background: #edeefa;
        border-radius: 8px;
        color: #3e44c8;

        span {
          color: #b5b6ba;
        }

        .badge {
          color: #fff;
        }
      }
    }

    .text-ligth {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #b5b6ba;
    }
  }
}

.dropdown-menu-footer {
  border-top: 1px solid #abb2f2;
  margin: 0 0.5rem;
  padding: 10px 0;
  justify-content: center;
  width: unset;

  button {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    color: #6f6b6b;
    background: transparent;
    border: unset;
    padding: 1rem;

    &:hover {
      background: #edeefa;
      border-radius: 8px;
      color: #3e44c8;
    }

    strong {
      margin-left: 0.5rem;
    }
  }

  &:hover {
    background: transparent;
  }
}
