// Here you can add other styles
* {
    outline: 0;
    box-sizing: border-box;
  }
  
  *:focus {
    outline: 0;
  }
  
  html, body, #root {
    height: 100%;
  }
  
  body {
    -webkit-font-smoothing: antialiased;
    background-color: #F2F2F8 !important;
  }
  
  body, input, button {
    font: 'Roboto', sans-serif;
  }
  
  select {
    option {
      left: 15px;
    }
  }
  
  // Menu lateral
  .sidebar {
    background: #ffffff;
    color: #edeefa;
  
    .nav {
      align-items: center;
      width: 220px;
    }
  
    .sidebar-nav {
      align-items: center;
      width: 220px;
    }
  
    .nav-title {
      color: #3e44c8;
      border-bottom: 1px solid #edeefa;
      width: 80%;
      padding-left: 0;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 144.14%;
      display: flex;
      align-items: center;
    }
  
    .nav-item {
      width: 100%;
      padding: 0.5rem 0;
      border-bottom: 0px solid #edeefa;
    }
  
    // Curvas
    // .nav-link {
    //   color: #edeefa;
    //   padding: 0.75rem 1.3rem;
    //   font-style: normal;
    //   font-weight: 500;
    //   font-size: 17px;
    //   line-height: 16px;
    //   display: flex;
    //   align-items: center;
    //   text-transform: capitalize;
  
    //   &:hover {
    //     color: #000;
    //     background: #f2f2f8;
    //     width:108%;
    //   }
  
    //   &.active {
    //     background: #f2f2f8;
    //     background-color:#f2f2f8;
    //     color: #000;
    //     width:108%;
    //     display:inline-block;
    //     height:90px;
    //     top:0;
    //     left:0;
    //     padding: 36px 0px 0px 25px;
    //   }
  
    //   &.active:before {
    //     content: "";
    //     position: absolute;
    //     top: 5px;
    //     right: 0px;
    //     height: 25px;
    //     width: 110%;
    //     background-color: rgb(0, 33, 121);
    //     border-bottom-right-radius: 80px;
    //     }
  
    //     &.active:after {
    //       content: "";
    //       position: absolute;
    //       top: 75px;
    //       right: 0px;
    //       height: 25px;
    //       width: 108%;
    //       background-color: rgb(0, 33, 121);
    //       border-top-right-radius: 80px;
    //     }
  
    .nav-link {
      color: #edeefa;
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 16px;
      display:inline-block;
      width: 90%;
      height: 65px;
      margin-left: 10px;
      padding: 25px 0px 0px 0px;
  
  
      &:hover {
        background: #f2f2f8;
        background-color:#f2f2f8;
        color: #000;
        width: 90%;
        display:inline-block;
        height: 65px;
        margin-left: 10px;
        border-radius: 20px 20px 20px 20px;
        padding: 25px 0px 0px 0px;
      }
  
  
      &.active {
        background: #f2f2f8;
        background-color:#f2f2f8;
        color: #000;
        width: 90%;
        display:inline-block;
        height: 65px;
        margin-left: 10px;
        border-radius: 20px 20px 20px 20px;
        padding: 25px 0px 0px 0px;
      }
      
      .nav-icon {
        position: absolute;
      }
  
      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  
    // .nav-icon {
    svg {
      // color: #6f6b6b !important;
      margin: 0 0.5rem 0 0;
      font-size: 20px;
    }
  
  }
  // Menu lateral
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  @media (max-width: 991.98px) {
    .app-header .navbar-brand {
      position: unset;
      top: 0;
      left: 0;
      margin-left: 0;
    }
  }
  
  button:focus {
    border: unset !important;
    outline: unset !important;
    box-shadow: unset !important;
  }
  
  .app-footer {
    background: transparent;
    // margin: 0 30px 0 230px !important;
    border-top: 1px solid #c9c9ce;
    margin: 0 auto 0 auto !important;
    padding: 1rem;
    width: 100%;
    bottom: 0;
  }
  
  .logoFooter {
    width: 50px;
    mix-blend-mode: luminosity;
  }
  
  .spanFooter {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #958f8f;
  }
  
  .limiteEmail{
    display: block;
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  // .app {
  //   background: #e5e5e5;
  // }
  
  .app-header .navbar-brand {
    width: 100px !important;
  }
  
  .app-header .nav-item {
    display: block !important;
  }
  
  @media (min-width: 992px) {
    .sidebar-minimized {
      .sidebar {
        .nav {
          align-items: flex-start;
        }
  
        .nav-item {
          width: 28px;
          overflow: hidden;
          margin: 0 1rem;
  
          &:hover {
            .nav-link{
              background: #edeefa !important;
              border-radius: 8px;
            }
          }
        }
  
        .nav-link {
          position: relative;
          padding-left: 0.25rem;
          margin: 0;
          white-space: nowrap;
          border-left: 0;
  
          &:hover {
            background: #edeefa !important;
            border-radius: 8px;
          }
        }
  
        .item-mov {
          .nav-link:hover {
            color: rgb(0, 33, 121);
          }
      
          .nav-link {
            &.active {
              background: rgb(0, 33, 121) !important;
              border-radius: 8px;
              color: #fff;
            }
          }
        }
      
        .item-prod {
          .nav-link:hover {
            color: #ff9600;
      
          }
      
          .nav-link {
            &.active {
              background: #ff9600 !important;
              border-radius: 8px;
              color: #fff;
            }
          }
        }
      
        .item-seg {
          .nav-link:hover {
            color: #4ac89b;
          }
      
          .nav-link {
            &.active {
              background: #4ac89b !important;
              border-radius: 8px;
              color: #fff;
            }
          }
        }
      
        .item-analises {
          .nav-link:hover {
            color: #6f6b6b;
          }
      
          .nav-link {
            &.active {
              background: #6f6b6b !important;
              border-radius: 8px;
              color: #fff;
            }
          }
        }
      }
    }
  }